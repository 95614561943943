import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import { Section, Title, Text, Box, ButtonIcon } from '../../components/Core'
import PageWrapper from '../../components/PageWrapper'
import Screenshot from '../../components/Screenshot'
import NextLink from '../../components/NextLink'
import WebsiteLink from '../../components/WebsiteLink'
import Slides from '../../components/Slides'
import Contact from '../../sections/common/Contact'
import Hero from '../../sections/common/Hero'
import { Keys, Key } from '../../sections/keys/Keys'

import favicon from '../../assets/images/aa/favicon.png'
import matchdocs from '../../assets/images/pd/match-doctors.png'

import homepage from '../../assets/images/ajc/homepage.jpg'
import algoliasearch from '../../assets/images/ajc/algolia-search.jpg'
import doctorprofiles from '../../assets/images/ajc/doctor-profile.jpg'
import footankle from '../../assets/images/ajc/foot-ankle.jpg'
import jointevaluationq from '../../assets/images/ajc/joint-evaluation-question.jpg'
import jointevaluation from '../../assets/images/ajc/joint-evaluation.jpg'
import videos from '../../assets/images/ajc/videos.jpg'

const WorkSingle = () => {
    return (
        <>
            <PageWrapper>
                <Hero className="mt-lg-5">
                    <Container>
                        <Row>
                            <Col lg="8">
                                <Text variant="tag">Personal</Text>
                                <Title mb="0.5em">Swimming Pool Rebuild</Title>
                                <Text variant="p" mb="1.6em">
                                    I always like to challenge myself, even when
                                    I'm working on projects at home.
                                </Text>
                                <Text variant="p" mb="1.6em">
                                    I wouldn't exactly consider myself a
                                    handyman, but when properly motivated I fell
                                    like I can solve any problem given to me.
                                </Text>
                                <Text variant="p" mb="1.6em">
                                    When covid lockdowns came to Canada I needed
                                    a project that got me out of the house. The
                                    pool liner of our pool was leaking and
                                    causing corrosion damage to the pool walls
                                    and the entire pool was showing signs of an
                                    imminent collapse.
                                </Text>
                            </Col>
                            <Col lg="4">
                                <img
                                    src={matchdocs}
                                    alt="Website Analytics"
                                    className="img-fluid w-100"
                                />
                            </Col>
                        </Row>
                    </Container>
                </Hero>

                <div className="mt-lg-3">
                    <Container>
                        <Row>
                            <Col lg="4" className="mb-4 mb-lg-0">
                                <Text variant="tag">Client</Text>
                                <Title variant="cardBig" className="mt-3">
                                    Personal
                                </Title>
                            </Col>
                            <Col lg="4" className="mb-4 mb-lg-0">
                                <Text variant="tag">Time</Text>
                                <Title variant="cardBig" className="mt-3">
                                    2020
                                </Title>
                            </Col>
                            <Col lg="4">
                                <Text variant="tag">Website</Text>

                                <Title variant="cardBig" className="mt-3">
                                    <WebsiteLink
                                        src={favicon}
                                        href="https://www.atlanticjointcenter.com"
                                    >
                                        atlanticjointcenter.com
                                    </WebsiteLink>
                                </Title>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <Section className="mt-lg-5">
                    <Container>
                        <Row>
                            <Col lg="5" className="mb-5 pr-lg-5">
                                <img
                                    src={matchdocs}
                                    alt="Match Docs"
                                    className="img-fluid w-100"
                                />
                            </Col>
                            <Col lg="7" className="mb-5 pl-lg-5">
                                <Title variant="cardBig" className="mb-4">
                                    Summer of Covid Lockdowns
                                </Title>
                                <Text variant="p" mb="1.6em">
                                    The summer of 2020 was an incredible
                                    difficult time for everyone. The only thing
                                    I could do to help was to keep my family
                                    occupied during a very difficult and
                                    isolated time.
                                </Text>
                                <Text variant="p" mb="1.6em">
                                    With the pool being a safety hazard, I
                                    needed to do something and there was nobody
                                    available to work on it, I had to take on
                                    the challenge myself.
                                </Text>
                                <Text variant="p" mb="1.6em">
                                    Equipped with only youtube as my backbone of
                                    knowledge about pools, I started consuming
                                    pool rebuild videos and began ordering the
                                    parts and tools required to rebuild.
                                </Text>

                                <Text variant="p" mb="1.6em">
                                    It took me approximtely 4 weeks to rebuild
                                    but by the end of June we had a wonderful
                                    new pool that would melt the covid blues
                                    away day after day.
                                </Text>
                            </Col>
                        </Row>
                    </Container>
                </Section>

                <Section mt="22%" bg="dark">
                    <Container>
                        <Row
                            css={`
                                margin-top: -35%;
                            `}
                        >
                            <Col xs="12" className="mb-5">
                                <Slides>
                                    <Screenshot
                                        src={homepage}
                                        alt="Atlantic Joint Center Homepage"
                                    />
                                    <Screenshot
                                        src={doctorprofiles}
                                        alt="Doctor Profiles"
                                    />
                                    <Screenshot
                                        src={algoliasearch}
                                        alt="Algolia Search"
                                    />
                                    <Screenshot
                                        src={jointevaluation}
                                        alt="Joint Evaluation"
                                    />
                                    <Screenshot
                                        src={jointevaluationq}
                                        alt="Joint Evaluation Question"
                                    />
                                    <Screenshot
                                        src={footankle}
                                        alt="Foot and Ankle Landing Page"
                                    />
                                    <Screenshot
                                        src={videos}
                                        alt="Joint Pain Animation Videos"
                                    />
                                </Slides>
                            </Col>
                        </Row>

                        <div className="text-center mt-lg-5">
                            <Text
                                variant="tag"
                                className="mb-1"
                                color="lightShade"
                            >
                                Next Project
                            </Text>
                            <NextLink to="/works/swimming-pool/">
                                Swimming Pool Rebuild
                            </NextLink>
                        </div>
                    </Container>
                </Section>

                <Box py={4}>
                    <Contact />
                </Box>
            </PageWrapper>
        </>
    )
}
export default WorkSingle
